import React, { useState, useEffect } from "react";

import axios from "axios";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

import FeedbackSessionCard from "../ FeedbackSessionCard/FeedbackSessionCard";

const upcomingFeedbackSessionDisplayText = (text) => {
  return (
    <Card className="text-center">
      <Card.Body>
        <Card.Title className="text-center mb-0">{text}</Card.Title>
      </Card.Body>
    </Card>
  );
};

const loadingFeedbackSessionContent = (
  <Card className="text-center">
    <Card.Body>
      <Spinner animation="border" />
    </Card.Body>
  </Card>
);

const errorFetchingFeedbackSessionContent = upcomingFeedbackSessionDisplayText(
  "There were issues fetching upcoming feedback sessions"
);

const noFeedbackSessionContent = upcomingFeedbackSessionDisplayText(
  "There are currently no upcoming feedback sessions"
);

const buildCardFeedbackSessionContent = (upcomingFeedbackSessions) =>
  upcomingFeedbackSessions.map((upcomingFeedbackSession, index) => {
    const feedbackSessionCardProps = {
      title: { text: upcomingFeedbackSession.title },
      location: {
        text: upcomingFeedbackSession.location,
      },
      dateTimeNaive: {
        startDate: upcomingFeedbackSession.start_date,
        startTime: upcomingFeedbackSession.start_time,
        endDate: upcomingFeedbackSession.end_date,
        endTime: upcomingFeedbackSession.end_time,
      },
      bookingLink: {
        url: upcomingFeedbackSession.booking_link,
      },
    };

    return <FeedbackSessionCard key={index} {...feedbackSessionCardProps} />;
  });

const FeedbackSessionCardGroup = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let fetchHeaders = {};
    if (process.env.GATSBY_WSIA_BACKEND_API_TOKEN) {
      fetchHeaders = {
        Authorization: `Token ${process.env.GATSBY_WSIA_BACKEND_API_TOKEN}`,
      };
    }
    const backendUrl = `${process.env.GATSBY_WSIA_BACKEND_BASE_URL}/api/upcoming_events/feedback_sessions/`;

    axios
      .get(backendUrl, { headers: fetchHeaders })
      .then((response) => {
        setData(response.data);
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        // eslint-disable-next-line no-console
        console.error("Error fetching feedback sessions:", error);
      });
  }, []);

  let cardGroupContent = noFeedbackSessionContent;
  if (loading) {
    cardGroupContent = loadingFeedbackSessionContent;
  } else if (error) {
    cardGroupContent = errorFetchingFeedbackSessionContent;
  } else if (data.length) {
    cardGroupContent = buildCardFeedbackSessionContent(data);
  }

  return <div className="card-group">{cardGroupContent}</div>;
};

export default FeedbackSessionCardGroup;
