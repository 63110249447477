import React from "react";

import _isEmpty from "lodash/isEmpty";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";

import GoogleTranslate from "components/GoogleTranslate/GoogleTranslate";
import Link from "components/Links/Link";
import { footerConfig } from "src/app-config";

const createFooterLink = (link) => {
  return (
    <Link
      className="text-primary"
      path={link.path}
      targetModalId={link.modalId}
    >
      {link.text}
    </Link>
  );
};

const createFooterItem = (item, key) => {
  if (item === "GoogleTranslate") {
    return <GoogleTranslate key={key} />;
  }
  return null;
};

const createLeftContent = (leftConfig) => {
  const { copyrightText, links } = leftConfig;

  if (_isEmpty(links)) {
    return <Nav.Item>{copyrightText}</Nav.Item>;
  }

  return links.map((linkConfig, index) => {
    // Place the first link on the same line as the copyright text,
    // e.g: `Copyright | privacy policy`.
    if (index === 0) {
      return (
        <Nav.Item key={index}>
          {copyrightText}
          {createFooterLink(linkConfig)}
        </Nav.Item>
      );
    }

    return (
      <Nav.Item className="pl-sm-2" key={index}>
        {createFooterLink(linkConfig)}
      </Nav.Item>
    );
  });
};

const createRightContent = (rightContent) => {
  return rightContent
    ? footerConfig.rightContent.map((footerItem, index) => {
        return createFooterItem(footerItem, `footer-right-${index}`);
      })
    : null;
};

const Footer = () => {
  const { leftContent, rightContent } = footerConfig;

  return (
    <>
      <Navbar
        sticky="bottom"
        className="bg-white border-top border-primary footer"
        expand={false}
      >
        <Container className="text-primary">
          <Nav>{createLeftContent(leftContent)}</Nav>
          <Nav>{createRightContent(rightContent)}</Nav>
        </Container>
      </Navbar>
      <Container style={{ fontSize: "0.8rem" }} className="text-primary">
        <Row className="align-items-center">
          <img
            className="col-2 col-md-1 p-1 p-sm-3"
            src="/icons/Interpreter.png"
            alt="Translating and Interpreting Service icon"
          />
          <p className="col-10 col-md-11 m-0">
            If you require the service of an interpreter, please contact the
            Translating and Interpreting Service on{" "}
            <span className="text-nowrap">131 450</span> and ask them to call
            the WSI Flight Paths team on{" "}
            <span className="text-nowrap">1800 038 160</span>
          </p>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
