import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import EventCardGroup from "components/ContentGroup/EventCardGroup";
import FeedbackSessionCardGroup from "components/ContentGroup/FeedbackSessionCardGroup";
import GalleryGroup from "components/ContentGroup/GalleryGroup";
import Row from "react-bootstrap/Row";
import * as React from 'react';
export default {
  Col,
  Container,
  EventCardGroup,
  FeedbackSessionCardGroup,
  GalleryGroup,
  Row,
  React
};