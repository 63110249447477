import React from "react";

import PropTypes from "prop-types";
import {
  GeoAlt,
  Clock,
  CalendarEvent,
  BoxArrowUpRight,
} from "react-bootstrap-icons";
import Card from "react-bootstrap/Card";

import { formatEventDateTime } from "../EventCard/utils";
import { withImageData } from "../Links/common";

const DATE_DISPLAY_FORMAT = "cccc, d MMMM yyyy"; // Saturday, 22 July 2023
const TIME_DISPLAY_FORMAT = "h:mma"; // 11:00AM
const FeedbackSessionCard = withImageData((props) => {
  const cardContent = (
    <Card>
      <Card.Body>
        <Card.Title style={props.title.style} className="mb-1">
          {props.title.text}
        </Card.Title>

        {props.dateTimeNaive && (
          <div className="event-card-icon-text">
            <CalendarEvent className="mr-2" />
            <Card.Text style={props.dateTimeNaive.style}>
              Date:{" "}
              {formatEventDateTime(
                [props.dateTimeNaive.startDate],
                DATE_DISPLAY_FORMAT
              )}
            </Card.Text>
          </div>
        )}

        {props.dateTimeNaive && (
          <div className="event-card-icon-text">
            <Clock className="mr-2 event-card-icon" />
            <Card.Text style={props.dateTimeNaive.style}>
              Time:{" "}
              {formatEventDateTime(
                [props.dateTimeNaive.startTime, props.dateTimeNaive.endTime],
                TIME_DISPLAY_FORMAT
              )}{" "}
              AEDT
            </Card.Text>
          </div>
        )}

        {props.location && (
          <div className="event-card-icon-text">
            <GeoAlt className="mr-2 event-card-icon" />
            <Card.Text style={props.location.style}>
              Location: {props.location.text}
            </Card.Text>
          </div>
        )}

        {props.bookingLink && (
          <div className="event-card-icon-text">
            <BoxArrowUpRight className="mr-2 event-card-icon" />
            <Card.Text style={props.bookingLink.style}>
              Booking link:{" "}
              <a
                href={props.bookingLink.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.bookingLink.url}
              </a>
            </Card.Text>
          </div>
        )}
      </Card.Body>
    </Card>
  );

  return <div className="event-card">{cardContent}</div>;
});

FeedbackSessionCard.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }).isRequired,
  description: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  location: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  dateTimeNaive: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string,
    style: PropTypes.string,
  }),
  bookingLink: PropTypes.shape({
    url: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
};

export default FeedbackSessionCard;
